"use server";
import React from "react";
import { Chart, ArcElement } from "chart.js";
import { Box, Stack } from "@mui/system";
import { abbreviateNumber } from "helpers/helpers";
import Placeholder from "assets/images/placeholder.png";
Chart.register(ArcElement);

import { PieChart } from "@mui/x-charts/PieChart";
import { useDrawingArea } from "@mui/x-charts/hooks";
import { styled } from "@mui/material/styles";
import { createRandomColors } from "helpers/helpers";
import { useLocalStorage } from "contexts/LocalStorageContext";

interface Props {
  redemptionSummary: any;
  valueType: string;
}

export default function DoughnutChart({ redemptionSummary, valueType }: Props) {
  const { isMobile } = useLocalStorage();
  const allValues =
    redemptionSummary?.map((item: any) => item.total_points) || [];

  const addUpAllValues = (arr: any) => {
    return arr.reduce((a: any, b: any) => a + b, 0);
  };

  const colors = createRandomColors(redemptionSummary?.length);
  const pointsValue = addUpAllValues(allValues);
  const cashValue = addUpAllValues(allValues) / 100;

  const customLegend = () => {
    return (
      <Stack spacing={7} overflow="auto" width="250px" p={1}>
        {redemptionSummary?.map((item: any) => {
          return (
            <Stack key={item.id} sx={{ position: "relative", height: "20px" }}>
              <Stack
                zIndex={2}
                position="absolute"
                direction="row"
                spacing={1}
                fontSize={12}
                fontWeight={500}
              >
                <img
                  width="60px"
                  height="100%"
                  style={{
                    borderRadius: "8px",
                    border: "1px solid #fff",
                    boxShadow: `-2px -2px 6px 1px ${
                      colors[redemptionSummary?.indexOf(item)]
                    }`,
                  }}
                  src={
                    item.image_url === null
                      ? (Placeholder.src as any)
                      : item.image_url
                  }
                  alt={item.name.slice(0, 4) + "..."}
                />{" "}
                <Stack>
                  <Stack whiteSpace="nowrap">
                    {item.name.length > 20
                      ? item.name.slice(0, 20) + "..."
                      : item.name}
                  </Stack>
                  <Box fontSize="0.6rem" whiteSpace="nowrap">
                    {valueType === "points" ? "" : "$"}
                    {valueType === "points"
                      ? item.total_points
                      : item.total_points / 100}{" "}
                    {valueType === "points" ? "Points" : ""}
                  </Box>
                </Stack>
              </Stack>
              <Stack
                position="absolute"
                top="-3px"
                left="-3px"
                direction="row"
                width="60px"
                height="40px"
                borderRadius="8px"
                bgcolor={colors[redemptionSummary?.indexOf(item)]}
              />
            </Stack>
          );
        })}
        <Stack height="10px" />
      </Stack>
    );
  };

  const data =
    redemptionSummary?.map((item: any) => ({
      value: item.total_points,
      label: item.name,
    })) || [];

  const size = {
    width: isMobile ? 300 : 380,
    height: 350,
  };

  const StyledText = styled("text")(({ theme }) => ({
    fill: theme.palette.text.primary,
    textAnchor: "middle",
    dominantBaseline: "central",
    fontSize: 30,
    fontWeight: "bold",
  }));

  function PieCenterLabel({ children }: { children: React.ReactNode }) {
    const { width, height, left, top } = useDrawingArea();
    return (
      <StyledText x={left + width / 2} y={top + height / 2}>
        {children}
      </StyledText>
    );
  }

  return (
    <Stack
      direction="row"
      height="100%"
      width="100%"
      justifyContent="space-between"
      flexWrap={isMobile ? "wrap" : "nowrap"}
    >
      {redemptionSummary?.length !== 0 ? (
        <Stack
          alignItems="center"
          // width={redemptionSummary !== undefined ? "500px" : "100%"}
          // width="500px"
          // height="300px"
        >
          <PieChart
            margin={{
              top: 20,
              bottom: 20,
              left: 20,
              right: 20,
            }}
            slotProps={{
              legend: { hidden: true },
            }}
            series={[{ data, innerRadius: 110 }]}
            {...size}
          >
            <PieCenterLabel>
              {valueType === "points" ? "" : "$"}
              {valueType === "points"
                ? abbreviateNumber(pointsValue)
                : cashValue}
              <br />
              {valueType === "points" ? " Points" : ""}
            </PieCenterLabel>
          </PieChart>
        </Stack>
      ) : (
        <Stack
          height="100%"
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          No data available
        </Stack>
      )}
      {redemptionSummary !== undefined && redemptionSummary?.length !== 0
        ? customLegend()
        : null}
    </Stack>
  );
}
